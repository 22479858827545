// Themes :: prmadv
[data-theme="prmadv"] {
  // Variables
  $primary: #ffb703;
  $secondary: black;
  $link-color: $primary;

  // Custom CSS
  @import "../custom_theme";

  // Branding
  .navbar-brand {
    background: url("./logo.png");
    background-repeat: no-repeat;
    background-size: auto 40px;
  }
}
