.image-carousel {
  height: 100%;
  width: 100%;

  .carousel-content-container {
    height: 100%;
    width: 100%;

    .carousel-image-container {
      height: 100%;
      width: 100%;

      .carousel-img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}
