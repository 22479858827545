@import "./../../theme/variables";

// css for media-sites-selection-page
.btn__draw {
  box-shadow: 0 6px 6px -2px #99c9ea;
  height: 35px;
  min-width: 200px;
  font-weight: 900;
}

.font-icon {
  padding: 3rem;
  margin: 2rem;
  opacity: 0.2;
}

// media-log-page css
// media-image
.media-image-map-container {
  height: 298px;
}

// default-image font-awesome
.log-font-img {
  width: -webkit-fill-available;
  height: 100%;
  text-align: center;
  opacity: 0.2;
  padding: 40px;
}

// log-record-image clicked by user
.log-record-image {
  width: 272px;
  height: 272px;
  padding: 8px;
}

// log Image at monitoring summary page
.log-image {
  width: 50px;
  height: 50px;
}
