// Bootstrap Default Colors
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

$primary: $blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$pending: $orange !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;

// MAVIN V2 Colors
// $primary: #0db59b;
// $secondary: #2d3e50;

// Bootstrap Overrides
$blue: #1968fc;
$border-radius: 0.125rem;
$body-bg: $white;
$font-size-base: 0.875rem;
$box-shadow-lg: 0 0 2rem 0.5rem rgba($gray-500, 0.175);
$box-shadow: 0 0 1rem 0.25rem rgba($gray-500, 0.15);
$box-shadow-sm: 0 0 0.5rem 0.125rem rgba($gray-500, 0.075);
$input-btn-padding-x: 2rem;
$input-padding-x: 0.75rem;
$input-btn-padding-x-sm: 1.25rem;
$input-padding-x-sm: 0.5rem;
$input-btn-padding-x-lg: 2.5rem;
$input-padding-x-lg: 1rem;
$grid-gutter-width: 2rem !default;
$breadcrumb-divider: quote(">");

// Additional Variables
$body-bg-alt: #f3f7ff;

$main-header-height: 4rem;
$main-footer-height: 0rem;
$zindex-main-header: 2000;

$navbar-padding-x: #{$grid-gutter-width / 2};
$navbar-padding-y: 0.25rem;
$nav-link-height: 1.125rem;

$sidebar-width: 15rem;

$content-padding-x: #{$grid-gutter-width / 2};
$content-padding-y: 1.5rem;

// Adding colors to the existing list
$theme-colors: (
  "light-green": #abd887,
  "light-blue": #86affd,
  "light-pink": #fdb9a4,
  "light-yellow": #ffeb80,
);
