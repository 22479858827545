.report-generate-btn {
  position: absolute;
  bottom: 0;
  right: 0;
  padding-right: 1rem;
}

.single-site-detail {
  height: 400px;
  width: 100%;
}