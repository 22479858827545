// Theme Variables
@import "./../../theme/variables";

// Custom CSS

.nav-item {
  .btn-link {
    padding: 0.25rem 0.75rem !important;
  }

  i.fas {
    padding-right: 5px;
    margin-top: -8px;
  }
}

.whats-new {
  .brand-logo {
    max-width: 150px;
    height: 50px;
  }
}
