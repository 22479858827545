@import "./../../theme/variables";
@import "./../../theme/style.scss";

// CSS
.org-map-cont {
  position: relative;
}

//org profile logo
.org-logo {
  width: 130px;
}

// target-group css
.target-group {
  .list {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

.spinner-inside-search {
  position: absolute;
  top: 18px;
  right: 18px;
}
