.progress-status {
  display: flex;
  align-items: center;

  &__text {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }

  &__title {
    font-size: 16px;
  }

  &__subtitle {
    font-size: 14px;
  }
}