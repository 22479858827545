.font-icon {
  font-size: 80px;
  opacity: 0.2;
}
.city-selection {
  width: 363px;
}

.square{
  width: 15px;
  height: 15px;
}
