.table-reports, .table-campaigns {
  th {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
    font-weight: 300;

    &-info {
      font-size: 12px;
    }

    p {
      margin-bottom: 0;
    }
  }

  .camp-link {
    min-width: 120px;
  }
}
