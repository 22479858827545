@import "./../../theme/variables";

.breadcrumb {
  &__container {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 4px 0;
  }

  &__text {
    font-size: 16px;
  }
}

.map-header {

  .breadcrumb__container {
    margin-top: 15px;
  }
}
