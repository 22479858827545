@import "../../theme/variables";

$width-sec-map-tabs: 90px;
$color-border: #dee2e6;

// Prooh Component css
// -------------------------------------------------------------------
.p-card:nth-child(4) {
  padding-right: 0;
}

.performance-card .card {
  height: 125px;
}

.cont-map-view {
  height: 560px;
  border: 1px solid #b6d1d1;
  position: relative;
}

// Road Selection/ Campaign-Planning Page
// ------------------------------------------------------------------
.sub-text {
  font-size: 0.75rem;
  color: #6c757d;
  display: block;
}

.planning-road-container {
  height: 60%;
  overflow-y: auto;
}

.cpm-input {
  width: 90px !important;
  height: 20px !important;
}

.planning-layout-left {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 47%;
  border-right: 1px solid #ccc;
}

.planning-layout-right {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 53%;
  height: 100%;
}

.planning-layout-right-map {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 53%;
  height: 100%;
}

.preview-button {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 1000;
}

.toohl-map-container {
  height: 100%;
  margin-right: $width-sec-map-tabs;
}

.w-47 {
  width: 47%;
}

.toohl-map-tabs-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1806;
  width: $width-sec-map-tabs;
  background: white;
  border-right: 1px solid $color-border;

  .tab-item {
    padding: 8px 8px;
    text-align: center;
    height: 86px;
    font-size: 12px;
    line-height: 16px;
    cursor: pointer;
    border-bottom: 1px solid $color-border;

    &.active {
      background-color: #e8effe;
    }
  }

  .tab-cont {
    position: absolute;
    top: 0;
    right: $width-sec-map-tabs;
    bottom: 0px;
    z-index: 1801;
    min-width: 720px;
    border: 1px solid $gray-300;
    overflow-y: auto;
    margin: 2px;
    padding: 20px 20px;
  }
}

// media-selection page
// -------------------------------------------------------------------

.overview-container {
  height: 170px;

  .chips-container {
    min-width: 130px;
  }

  .scrollable-container {
    height: 100px;
    overflow-y: auto;
  }
}


.cont-media-selection {
  height: 450px;
  overflow: auto;
}

.media-selection {
  .rs-picker-toggle {
    padding: 0px !important;
  }
  .rs-picker-toggle-caret {
    display: none;
  }
}

.media-tag {
  width: 20px !important;
  height: 20px !important;
  border-radius: 50%;
  font-weight: 800;
}

// Seller-site-earning page
// ------------------------------------------------------------------
.site-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

// TG-Selection-Form
// --------------------------------------------------------------------
#target-group-form {
  .form-body {
    height: 400px;
    overflow-y: auto;
  }
}

// Add Captive Area Popup Css
// -------------------------------------------------------------------
#add-poi-captive-area-form {
  .captive {
    &__map-container {
      height: 300px;
      width: 579px;
      float: right;
    }
  }

  input:focus-visible {
    outline: none;
  }
}

// road stretches table in campaign
.stretch-info-cell {
  width: 200px;
}

#tab-content-preview-form {
  .modal-body {
    height: 400px;
    max-height: 600px;
    overflow-y: auto;
  }
}
