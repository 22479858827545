.data-layer-marker {
  border: 1px solid #c11111;
  clip-path: polygon(0% 100%, 50% 0%, 100% 100%);

  &.layer {
    &_0 {
      background-color: #0000FF;
    }

    &_1 {
      background-color: #FFD700;
    }

    &_2 {
      background-color: #2F4F4F;
    }

    &_3 {
      background-color: #FF8C00;
    }

    &_4 {
      background-color: #00008B;
    }

    &_5 {
      background-color: #00FF00;
    }

    &_6 {
      background-color: #B22222;
    }

    &_7 {
      background-color: #F08080;
    }

    &_8 {
      background-color: #8B0000;
    }

    &_9 {
      background-color: #BA55D3;
    }

    &_10 {
      background-color: #A1683A;
    }

    &_11 {
      background-color: #243010;
    }

    &_12 {
      background-color: #FE6847;
    }

    &_13 {
      background-color: #0095ff;
    }

    &_14 {
      background-color: #4d1162;
    }

    &_15 {
      background-color: #370926;
    }

    &_16 {
      background-color: #874000;
    }

    &_17 {
      background-color: #92B4A7;
    }

    &_18 {
      background-color:#685155;
    }

    &_19 {
      background-color: #985F6F;
    }
  }
}

