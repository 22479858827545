// Site- analyzer CSS
.autocomplete-map {
  position: absolute;
  z-index: 1000;
}

.camera-analytic-video-cont {
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px !important;
  border-color: #b6b9b8 !important;
  margin-top: 8px;
}
