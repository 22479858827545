.card {
  &__media {
    height: 370px;
    overflow: hidden;
  }

  &__media-img {
    height: 200px;
  }
}

.page-banner {
  height: 230px;
  box-shadow: 0 20px 60px 0 #3145f41a;
}

.seller-website-page {
  .img-thumbnail-lg {
    object-fit: contain;
  }
}

.seller-website-media-card.disabled {
  pointer-events: none;
  cursor: default;
}
