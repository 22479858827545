// Theme Variables
@import "../../../theme/variables";
@import "../../../theme/style.scss";

// Custom CSS

.overview-page {
  .tab-header.active {
    border-bottom: 2px solid #1968fc;
  }
}

select.decorated option:hover {
  box-shadow: 0 0 10px 100px #1882a8 inset;
}

input[type="radio"] {
  // transform: scale(2);
}

.form-check-input {
  margin-left: 30px;
  position: relative;
}

.image-thumbnail {
  height: 65px;
  width: 100px;
}

table.table-media .th-adjust {
  text-align: end;
  padding-right: 63px;
}

.fa-images.fa__opacity {
  opacity: 0.2;
}

table.table-media tr {
  .w-30 {
    width: 30%;
  }

  .w-26 {
    width: 26%;
  }

  .w-10 {
    width: 10%;
  }

  .w-17 {
    width: 17%;
  }

  .w-20{
    width: 20%;
  }

  .w-8 {
    width: 8%;
  }

  .w-9 {
    width: 9%;
  }
}

.site-details {
  @extend h4.sec-title;
  margin-bottom: 0px;
}

.map-img-cont {
  height: 600px;
  width: -webkit-fill-available;
}
