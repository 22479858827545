.media {
  &__site-image {
    height: 65px;
    width: 65px;
    object-fit: cover;
  }

  &__default-image {
    height: 65px;
    width: 65px;
    object-fit: cover;
    opacity: 0.2;
  }
}
