@import "../../theme/variables";

$height-map-header: $main-header-height;
$width-sec-map-tabs: 90px;
$width-sec-map-controls: 250px;
$color-border: #dee2e6;

.map-content {
  height: 100%;
  .map-tabs-cont {
    position: absolute;
    top: $height-map-header;
    left: 0;
    bottom: 0;
    z-index: 1806;
    width: $width-sec-map-tabs;
    background: white;
    border-right: 1px solid $color-border;
  }

  .map-cont {
    height: calc(100% - #{$height-map-header});
    margin-top: $height-map-header;
    margin-left: $width-sec-map-tabs;
    margin-right: $width-sec-map-controls;
    position: relative;
  }

  .map-controls-cont {
    position: absolute;
    top: $height-map-header;
    left: calc(100% - #{$width-sec-map-controls});
    bottom: 0;
    z-index: 1806;
    border-left: 1px solid $color-border;
    width: $width-sec-map-controls;
  }
}

.map-tabs-cont {
  .tab-item {
    i {
      height: 20px;
      width: 24px;
      font-size: 16px;
    }

    padding: 8px 8px;
    text-align: center;
    height: 86px;
    color: $blue;
    font-size: 12px;
    line-height: 16px;
    cursor: pointer;
    border-bottom: 1px solid $color-border;

    &.active {
      background-color: #e8effe;
    }
  }

  .tab-cont {
    position: absolute;
    top: 0;
    left: $width-sec-map-tabs;
    bottom: 0px;
    z-index: 1801;

    min-width: 720px;

    border: 1px solid $gray-300;
    overflow-y: auto;
    margin: 2px;
    padding: 20px 20px;
  }
}

.map-controls-cont {
  .header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    border-bottom: 1px solid $color-border;
  }

  .sec {
    padding-bottom: 4px;
    margin-bottom: 8px;

    .sec-title {
      margin-bottom: 2px;
    }
  }

  .form-check {
    padding-left: 8px;

    .form-check-input {
      margin-left: 0px;
    }

    .form-check-label {
      margin-left: 6px;
    }
  }

  .form-group {
    padding-left: 8px;
  }
}

// CSS for LENGEND (meta-data show)
.media {
  &__metaData {
    margin-top: 15px;

    &--checkBox {
      margin-bottom: 0px !important;
    }
  }

  &__collapse-icon {
    cursor: pointer;
    font-size: 16px;
  }
}

// Media Icon CSS
.icon-media {
  // normal media icon
  span {
    width: 16px !important;
    height: 16px !important;
    border-radius: 50%;
    border: 1px solid $gray-600;
    opacity: 0.8;
    display: block;
  }

  // org media icon
  &.star {
    span {
      width: 23px !important;
      height: 23px !important;
      aspect-ratio: 1;
      margin-top: -4px;
      margin-left: -4px;
      clip-path: polygon(
        50% 0%,
        61% 35%,
        98% 35%,
        68% 57%,
        79% 91%,
        50% 70%,
        21% 91%,
        32% 57%,
        2% 35%,
        39% 35%
      );
    }
  }
}

div[disabled] {
  pointer-events: none;
  background: $gray-200 !important;
  opacity: 0.7;
}

.city-analyser-tool {
  .top-0 {
    top: 0 !important;
  }
}

.map-info-box {
  position: absolute;
  z-index: 1806;
  top: 1rem;
  right: 1rem;
  height: 150px;
  width: 250px;
}
