$color-border: #dee2e6;

.map-controls-cont {
  position: absolute;
  left: 0;
  top: 0px;
  bottom: 0;
  z-index: 1806;
  border-left: 1px solid $color-border;
  width: 250px;
  background-color: #fff;
}
