@import "../../../theme//variables";

.media-page {
  .circle {
    border-radius: 50%;
    height: 70px;
    width: 70px;
    background-color: rgba(25, 104, 252, 0.05);
  }

  .page-title {
    color: $gray-900;
    font-size: 26px;
    letter-spacing: 0;
    line-height: 24px;
  }

  .metric-name {
    color: $gray-900;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
    margin-bottom: 10px;
  }

  .metric-value {
    color: $gray-900;
    font-size: 28px;
    letter-spacing: 0;
    line-height: 40px;
    margin-bottom: 0;
  }

  .metric-additional-info {
    color: rgba(17, 34, 63, 0.7);
    font-size: 12px;
    letter-spacing: 0;
    line-height: 17px;
  }

  .impression-change {
    font-size: 12px;
    position: relative;
    bottom: 6px;
    left: 10px;
  }

  .map-image-container,
  .media-title-container {
    margin: 50px 0;
  }

  .image-container {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    flex-direction: column;

    .preview {
      width: 100%;
    }
  }

  .leaflet-container {
    height: 500px;
    z-index: 10;
  }

  .fa-images {
    color: #75a4fe;
  }

  .calendar-element {
    margin-left: 50px;
    width: 50%;
  }

  .blur-cover {
    width: 96%;
    height: 150px;
    position: absolute;
    z-index: 5;
    color: $black;
    background-color: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);

    p {
      margin-top: 65px;
      font-weight: bold;
    }
  }
  .bg-blur {
    opacity: 0.2;
    flex: 1;
  }

  .download-report {
    margin-right: 50px;
  }
}
