// CSS :: Custom Theme
// ----------------------------------------------------------------------------
// Define below variable
// - $primary
// - $secondary
// - $link-color
// ----------------------------------------------------------------------------

// Inputs

// Links

a {
  color: $link-color !important;

  &:hover {
    color: darken($link-color, 15%) !important;
  }
}

.btn-link {
  color: $link-color !important;

  &:hover {
    color: darken($link-color, 15%) !important;
  }
}

.btn-primary {
  color: black !important;
  background-color: lighten($link-color, 8%) !important;
  border-color: lighten($link-color, 10%) !important;

  &:hover {
    color: black !important;
    background-color: $link-color !important;
    border-color: lighten($link-color, 5%) !important;
  }
}

.btn-outline-primary {
  color: $primary !important;
  border-color: lighten($link-color, 5%) !important;

  &:hover {
    color: $white !important;
    background-color: $link-color !important;
    border-color: lighten($link-color, 5%) !important;
  }
}

.text-primary {
  color: $primary !important;
}

// Component :: Tabs

.tab-header {
  &.active {
    border-bottom: 1px solid $primary;
  }
}

// Component :: Pagination

.page-item {
  &.active {
    .page-link {
      color: $white !important;
      background-color: $primary;
      border-color: $primary;
    }
  }
}

// Other Reset

.checkbox-container--selected {
  background-color: lighten($primary, 55%) !important;
}

// Other Reset :: rs-picker-daterange
a.rs-btn {
  color: $link-color !important;

  &:hover {
    color: darken($link-color, 15%) !important;
  }
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: $primary !important;
}
