@import "./../../theme/variables";
@import "./../../theme/style.scss";

.feature-card {
  min-height: 220px;
  width: 395px;
  margin: 0 1rem;
  margin-bottom: $grid-gutter-width;

  .card-body {
    padding: 24px;
  }

  .card-title {
    margin-bottom: 30px;
  }

  .btn {
    margin-top: 10px;
    text-transform: uppercase;
  }
}