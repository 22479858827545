@import "./../../theme/variables";
@import "./../../theme/style.scss";

.filter-dropdown {
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 0px;
  width: 323px;
  padding: 20px !important;
  // left: 2px !important;
  transform: translate3d(-172px, 40px, 0px) !important;
}