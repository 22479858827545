.autocomplete {
  height: 35px;
  border-radius: 5px;
  border: 1px solid #dee2e6;
  padding-left: 0.5rem;
}

.pac-container {
  z-index: 2051;
}
