.deselect {
  position: absolute;
  right: -2px;
  top: -2px;
  border: 1px solid black;
  cursor: pointer;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.selected-item-width {
  width:130px ;
}